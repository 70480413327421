<template>
  <section class="carousel-section">
    <el-carousel arrow="always" class="carousel-custom">
      <el-carousel-item v-for="item in items" :key="item.id">
        <div class="carousel-item-content">
          <div class="carousel-text">
            <h2 class="carousel-title">{{ item.title }}</h2>
            <p class="carousel-description">{{ item.description }}</p>
            <div class="carousel-buttons">
              <el-button class="trial-button">免费试用</el-button>
              <el-button class="consult-button">立即咨询</el-button>
            </div>
          </div>
          <div class="carousel-image">
            <img :src="item.image" alt="carousel image" />
          </div>
        </div>
      </el-carousel-item>
      <template #indicator="scope">
        <el-carousel-indicator :active="scope.active">
          <span
            class="carousel-indicator"
            :class="{ 'is-active': scope.active }"
          ></span>
        </el-carousel-indicator>
      </template>
      <template #arrow="scope">
        <button
          v-if="scope.direction === 'left'"
          class="carousel-arrow carousel-arrow-left"
          @click="scope.handleClick"
        >
          <img src="@/assets/carousel_pre_btn.png" alt="Previous" />
        </button>
        <button
          v-if="scope.direction === 'right'"
          class="carousel-arrow carousel-arrow-right"
          @click="scope.handleClick"
        >
          <img src="@/assets/carousel_next_btn.png" alt="Next" />
        </button>
      </template>
    </el-carousel>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: "多端适配 二次开发",
          description:
            "电脑端和移动端多端适配\n\r围绕企业独特需求进行二次开发\n\r专业团队为企业提供定制服务",
          image: require("@/assets/carousel-image1.png"),
        },
        {
          id: 2,
          title: "智能办公 高效管理",
          description: "智能办公系统，提升企业管理效率，降低运营成本。",
          image: require("@/assets/carousel-image2.png"),
        },
        {
          id: 3,
          title: "数据分析 精准决策",
          description: "全面的数据分析和管理，助力企业精准决策。",
          image: require("@/assets/carousel-image3.png"),
        },
        {
          id: 4,
          title: "高效协作 团队合作",
          description: "提升团队协作效率，实现高效办公。",
          image: require("@/assets/carousel-image4.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.carousel-section {
  position: relative;
  z-index: -1;
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  /* max-height: 1000px; */
  min-height: 680px;
  opacity: 1;
}

.carousel-custom {
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  margin: 0px auto;
  margin-top: -112px;
}
:deep(.el-carousel__container) {
  height: 100%;
}

.carousel-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 100vh;
  max-width: 1660px;
  min-width: 1000px;
  padding: 0 0px;
  box-sizing: border-box;
  margin: 0 auto;
  /* border: 1px solid #000; */
}
.carousel-text {
  max-width: 600px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.carousel-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  vertical-align: top;
  width: 100%;
  height: 70px;
  opacity: 1;
}

.carousel-description {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  text-align: left;
  vertical-align: top;
  width: 100%;
  height: 105px;
  opacity: 1;
  white-space: pre-wrap; /* 支持文本换行 */
}

.carousel-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

.trial-button {
  width: 210px;
  height: 67px;
  opacity: 1;
  border-radius: 33.33px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.39px 4.17px rgba(96, 108, 128, 0.05);
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.consult-button {
  width: 210px;
  height: 67px;
  opacity: 1;
  border-radius: 44.44px;
  background: rgba(240, 242, 245, 1);
  color: rgba(128, 128, 128, 1); /* 使用指定颜色 */
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 68px;
  height: 68px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.carousel-arrow-left {
  left: 43px;
}

.carousel-arrow-right {
  right: 43px;
}

.carousel-arrow img {
  width: 100%;
  height: 100%;
}

.el-carousel__indicator {
  bottom: 81px !important; /* 设置指示器距离底部的距离 */
}

.carousel-indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(128, 128, 128, 1);
  border-radius: 50%;
  display: inline-block;
  margin: 0 6px;
}

.carousel-indicator.is-active {
  background-color: rgba(22, 80, 139, 1); /* 使用主题色 */
}

.carousel-image {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: flex-end; /* 靠右 */
  height: 100%; /* 你可以根据需要调整高度 */
}

.carousel-image img {
  width: 550px;
  height: 570px;
}
</style>
