<template>
  <div>
    <AppHeader/>
    <HelpCenterContent/>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import HelpCenterContent from '../components/HelpCenterContent.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  name: 'HelpCenterView',
  components: {
    AppHeader,
    HelpCenterContent,
    AppFooter
  }
}
</script>
