<template>
  <div>
    <AppHeader/>
    <PurchaseServiceContent/>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import PurchaseServiceContent from '../components/PurchaseServiceContent.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  name: 'PurchaseServiceView',
  components: {
    AppHeader,
    PurchaseServiceContent,
    AppFooter
  }
}
</script>
