<template>
  <el-footer class="footer-section">
    <div class="footer-content">
      <div class="footer-logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <div class="footer-links">
        <div class="footer-column">
          <h3>产品</h3>
          <ul>
            <li>企业管理系统（桌面端）</li>
            <li>企业管理系统（小程序）</li>
            <li>BOSS助手（桌面端）</li>
            <li>BOSS助手（小程序）</li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>解决方案</h3>
          <ul>
            <li>Gantt Chart</li>
            <li>Dashboards</li>
            <li>Native Time Tracking</li>
            <li>Mind Maps</li>
            <li>Automations</li>
            <li>Notepad</li>
            <li>Integrations</li>
            <li>To-Do List</li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>服务</h3>
          <ul>
            <li>个性化定制</li>
            <li>产品使用手册</li>
            <li>vs Jira</li>
            <li>vs Trello</li>
            <li>vs Airtable</li>
            <li>vs Notion</li>
            <li>vs Basecamp</li>
            <li>vs Wrike</li>
            <li>vs MS Project</li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>联系我们</h3>
          <ul>
            <li>Blog</li>
            <li>Software Team Hub</li>
            <li>Agency Hub</li>
          </ul>
          <h3>下载</h3>
          <div class="download-buttons">
            <img src="@/assets/microsoft.png" alt="Microsoft" />
            <img src="@/assets/android.png" alt="Android" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>
        &copy; Copyright 2008-2024 南京探智信息科技有限公司 苏xxxx备17007324号-1
        苏公网安备 31011502003299号
      </p>
    </div>
  </el-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer-section {
  width: 100%;
  min-width: 1660px;
  background-color: #f7f9fa;
  padding: 0;
  text-align: left;
  height: fit-content;
}

.footer-content {
  width: 100%;
  min-width: 1200px;
  max-width: 1660px;
  height: 619px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 90px 0px;
  box-sizing: border-box;
  margin: 0 auto;
}

.footer-logo {
  width: 360px;
}

.footer-logo img {
  width: 161px;
  height: 66px;
  margin-top: 10px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 40px;
}

.footer-column {
  margin-right: 40px;
}

.footer-column h3 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  font-size: 18px;
  font-weight: 500;
  color: rgba(81, 75, 129, 1);
  margin-bottom: 18px;
}

.download-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.download-buttons img {
  width: 120px;
  height: auto;
}

.footer-bottom {
  height: 119px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(81, 75, 129, 1);
  text-align: center;
  border-top: 1.35px solid rgba(206, 206, 234, 1);
}
</style>
