<template>
  <el-main>
    <h1>帮助中心</h1>
    <p>这里是帮助中心页面的内容。</p>
  </el-main>
</template>

<script>
export default {
  name: 'HelpCenterContent'
}
</script>

<style scoped>
</style>
