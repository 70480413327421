<template>
  <section class="why-choose-section">
    <h2 class="section-title">
      <span class="title-grey">为什么</span>
      <span class="title-gradient">选择易企创</span>
    </h2>
    <p class="section-subtitle">
      打造服务企业的次世代智能管理工具，AI赋能您的企业运作。
    </p>
    <div class="choose-cards">
      <div v-for="(item, index) in chooseItems" :key="index">
        <div class="choose-card">
          <div class="card-header">
            <h3 class="card-title">{{ item.title }}</h3>
            <img :src="item.icon" alt="Icon" class="card-icon" />
          </div>
          <p class="card-description">{{ item.description }}</p>
          <el-link type="primary" class="card-link">了解详情→</el-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      chooseItems: [
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "个性自定义",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          icon: require("@/assets/value_icon.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.why-choose-section {
  padding: 60px 0;
  background: rgba(247, 249, 250, 1);
  text-align: center;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  margin-bottom: 10px;
}

.title-grey {
  color: rgba(166, 166, 166, 0.68);
}

.title-gradient {
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-subtitle {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  text-align: center;
  vertical-align: top;
  margin-bottom: 40px;
}

.choose-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choose-card {
  width: 490px;
  height: 360px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  padding: 35px;
  margin-bottom: 18px;
  margin-right: 18px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-icon {
  width: 88px;
  height: 88px;
}

.card-description {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(50, 62, 77, 1);
  flex: 1 1 auto;
}

.card-link {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(35, 132, 197, 1);
}
</style>
