<template>
  <section class="centered-text-image-section">
    <div class="text-container">
      <h2 class="centered-text">易企创企业价值链</h2>
      <div class="centered-image">
        <img src="@/assets/ad_bg.png" alt="ad background">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CenteredTextImageSection'
}
</script>

<style scoped>
.centered-text-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-bottom: 108px; /* 确保底部有108px的间距 */
}

.text-container {
  text-align: center;
}

.centered-text {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(90deg, rgba(22, 80, 139, 1) 0%, rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  vertical-align: top;
  width: 100%;
  height: 70px;
  opacity: 1;
  margin-bottom: 63px; /* 确保图片距离文字63px */
}

.centered-image img {
  width: 46%; /* 设置图片宽度为46% */
  height: auto;
}
</style>
