<template>
  <el-main>
    <h1>购买服务</h1>
    <p>这里是购买服务页面的内容。</p>
  </el-main>
</template>

<script>
export default {
  name: 'PurchaseServiceContent'
}
</script>

<style scoped>
</style>
