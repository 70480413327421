<template>
  <section class="centered-text-image-section">
    <div class="text-container">
      <div class="centered-text">易企创产品系统架构</div>
      <div class="centered-image">
        <img src="@/assets/product_full_intro.png" alt="ad background" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CenteredTextImageSection",
};
</script>

<style scoped>
.centered-text-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 72px;
  padding-bottom: 60px;
  background: rgba(255, 255, 255, 1);
}

.text-container {
  text-align: center;
}

.centered-text {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  vertical-align: top;
  width: 100%;
  height: 70px;
  opacity: 1;
  margin-bottom: 63px; /* 确保图片距离文字63px */
}

.centered-image img {
  width: 46%; /* 设置图片宽度为46% */
  min-width: 1200px;
  max-width: 1400px;
  margin-top: -10px;
  height: auto;
}
</style>
