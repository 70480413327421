<template>
  <div class="product-intro-section">
    <div class="product-intro-section-bg"></div>
    <div class="intro-section">
      <div class="intro-subtitle">高效、智能、便捷的企业管理解决方案</div>
      <div class="intro-title">中小企业营管数据智能服务平台</div>
      <p class="intro-description">
        助力中小企业数字化转型 赋能管理团队智能化决策
      </p>
    </div>
    <div class="features-section">
      <ProductFeature
        v-for="(feature, index) in features"
        :key="index"
        :icon="feature.icon"
        :title="feature.title"
        :description="feature.description"
        :url="feature.url"
      />
    </div>
  </div>
</template>

<script>
import ProductFeature from "./ProductFeature.vue";

export default {
  name: "ProductIntroContent",
  components: {
    ProductFeature,
  },
  data() {
    return {
      features: [
        {
          icon: require("@/assets/value_icon.png"),
          title: "企业管理系统",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          url: "/enterprise-management",
        },
        {
          icon: require("@/assets/value_icon.png"),
          title: "智能助手",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          url: "/smart-assistant",
        },
        {
          icon: require("@/assets/value_icon.png"),
          title: "大数据平台",
          description:
            "客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。",
          url: "/big-data-platform",
        },
      ],
    };
  },
};
</script>

<style scoped>
.product-intro-section {
  width: 100%;
  height: 1055px;
  text-align: center;
  position: relative;

  /* border: 1px solid #000; */
}
.product-intro-section-bg {
  z-index: 0;
  width: 100%;
  height: 919px;
  background-image: url("~@/assets/product_first_bg.png");
  background-size: cover;
  background-position: center;
  top: 0px;
  position: absolute;
}

.intro-section {
  padding: 130px 0;
  /* border: 1px solid #000; */
}

.intro-subtitle {
  font-size: 36px;
  font-weight: 700;
  color: rgba(128, 128, 128, 1);
  margin-bottom: 10px;
}

.intro-title {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.intro-description {
  font-size: 24px;
  font-weight: 400;
  color: rgba(50, 62, 77, 1);
}

.features-section {
  z-index: 10;
  height: 446px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 135px;
  right: 0px;
  left: 0px;
  margin: auto;

  /* border: 1px solid #000; */
}
</style>
