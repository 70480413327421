<template>
  <el-main>
    <CarouselSection />
    <div class="section-container">
      <CenteredTextImageSection />
    </div>
    <!-- <ValueSection /> -->
    <div class="section-bg-gray">
      <div class="section-container-max-big">
        <WhyChooseSection />
      </div>
    </div>
    <div class="section-bg-gray">
      <div class="section-container-min-big">
        <BossAssistantSection />
      </div>
    </div>

    <div class="section-container-min-big">
      <FAQSection />
    </div>
  </el-main>
</template>

<script>
import CarouselSection from "./sections/CarouselSection.vue";
import CenteredTextImageSection from "./sections/CenteredTextImageSection.vue";
// import ValueSection from "./sections/ValueSection.vue";
import WhyChooseSection from "./sections/WhyChooseSection.vue";
import BossAssistantSection from "./sections/BossAssistantSection.vue";
import FAQSection from "./sections/FAQSection.vue";

export default {
  components: {
    CarouselSection,
    CenteredTextImageSection,
    // ValueSection,
    WhyChooseSection,
    BossAssistantSection,
    FAQSection,
  },
};
</script>

<style scoped>
.el-main {
  padding: 0px;
  overflow: initial;
  width: 100vw;
  min-width: 1660px;
}
.page-main-container {
  width: 100vw;
  /* border: 1px solid #000; */
  margin: 0px auto;
}
.section-container {
  width: 100vw;
  min-width: 1200px;
  max-width: 1660px;
  margin: 0px auto;
  /* border: 1px solid #000; */
}
.section-bg-gray {
  width: 100%;
  background: rgba(247, 249, 250, 1);
}
.section-container-max-big {
  width: 100vw;
  min-width: 1200px;
  max-width: 1660px;
  /* border: 1px solid #000; */
  margin: 0px auto;
  box-sizing: border-box;
}
.section-container-min-big {
  width: 100vw;
  min-width: 1660px;
  max-width: 1660px;
  /* border: 1px solid #000; */
  margin: 0px auto;
  box-sizing: border-box;
}
</style>
