<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
</style>
