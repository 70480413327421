import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductIntroView from '../views/ProductIntroView.vue'
import HelpCenterView from '../views/HelpCenterView.vue'
import PurchaseServiceView from '../views/PurchaseServiceView.vue'

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/product-intro',
        name: 'ProductIntroView',
        component: ProductIntroView
    },
    {
        path: '/help-center',
        name: 'HelpCenterView',
        component: HelpCenterView
    },
    {
        path: '/purchase-service',
        name: 'PurchaseServiceView',
        component: PurchaseServiceView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
