<template>
  <div class="product-feature">
    <img :src="icon" alt="Icon" class="feature-icon" />
    <div class="feature-content">
      <h3 class="feature-title">{{ title }}</h3>
      <p class="feature-description">{{ description }}</p>
    </div>
    <img
      src="@/assets/product_next_icon.png"
      alt="Next"
      class="feature-next-icon"
      @click="navigate"
    />
  </div>
</template>

<script>
export default {
  name: "ProductFeature",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    navigate() {
      window.location.href = this.url;
    },
  },
};
</script>

<style scoped>
.product-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 30px;
  padding: 40px 45px;
  width: 368px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}

.feature-icon {
  width: 98px;
  height: 98px;
  margin-bottom: 0px;
}

.feature-content {
  flex-grow: 1;
}

.feature-title {
  font-size: 28px;
  font-weight: 700;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 20px;
  font-weight: 400;
  color: rgba(50, 62, 77, 1);
  margin-bottom: 20px;
}

.feature-next-icon {
  margin-bottom: -5px;
  width: 65px;
  height: 65px;
}
</style>
