<template>
  <div class="left-image-intro">
    <div class="box">
      <div class="image-content">
        <img
          src="@/assets/product_image_intro_2.png"
          alt="Product Image"
          class="product-image"
        />
        <img src="" alt="phone Image" class="phone-image" />
      </div>
      <div class="text-content">
        <div class="small-title">高效、值得信任的</div>
        <div class="main-title">BOSS助手</div>
        <div class="sub-title">
          助力中小企业数字化转型 赋能管理团队智能化决策
        </div>
        <div class="description">
          电脑端和移动端多端适配<br />
          围绕企业独特需求进行二次开发<br />
          专业团队为企业提供定制服务
        </div>
      </div>
    </div>
    <div class="background-image"></div>
  </div>
</template>

<script>
export default {
  name: "LeftImageIntro",
};
</script>

<style scoped>
.left-image-intro {
  width: 100%;
  height: 950px;
  position: relative;
  overflow: hidden;
  min-height: 600px; /* 确保有足够高度 */
  background-color: rgba(255, 255, 255, 1);

  /* border: 1px solid #000; */
}
.box {
  width: 100%;
  height: 590px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1660px;
  min-width: 1660px;
  margin: 0 auto;

  margin-top: 180px;

  /* border: 1px solid #000; */
}

.text-content {
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
  text-align: left;
}

.small-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 52.13px;
  color: rgba(128, 128, 128, 1);
  margin-top: 20px;
}

.main-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 69.5px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  margin-top: 185px;
}

.image-content {
  position: relative;
  width: 981.39px;
  height: 591px;
  height: auto;
  z-index: 2;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 24.52px;
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/product_image_bg_1.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.phone-image {
  z-index: 3;
  position: absolute;
  top: 180px;
  right: -120px;
  width: 237px;
  height: 488px;
  border-radius: 35px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
}
</style>
