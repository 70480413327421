<template>
  <el-header class="app-header">
    <div class="header-content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="1">
          <router-link to="/">首页</router-link>
        </el-menu-item>
        <el-menu-item index="2">
          <router-link to="/product-intro">产品介绍</router-link>
        </el-menu-item>
        <el-menu-item index="3">
          <router-link to="/help-center">帮助中心</router-link>
        </el-menu-item>
        <el-menu-item index="4">
          <router-link to="/purchase-service">购买服务</router-link>
        </el-menu-item>
      </el-menu>
      <div class="login-buttons">
        <el-button class="trial-button">免费试用</el-button>
        <el-button class="login-button">立即登录</el-button>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
};
</script>

<style scoped>
.app-header {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  border-radius: 20px;
  height: 112px;
  width: 100vw;
  min-width: 1660px;
  display: flex;
  align-items: center;
  padding: 0;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 1200px;
  max-width: 1660px;
  width: 73vw;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 202px;
  height: 83px;
}

.el-menu-demo {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* 居中菜单 */
  line-height: 60px;
  height: 112px;
  border-bottom: none !important;
  flex: 0 0 auto;
  width: 60%;
}

.el-menu-item {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: #414552;
  text-align: center;
  vertical-align: middle;
}

.el-menu-item a {
  line-height: 112px;
  height: 112px;
}

.el-menu-item.is-active {
  border-width: 4px !important;
}

.login-buttons {
  display: flex;
  align-items: center;
  overflow: visible;
}

.trial-button {
  width: 100px;
  height: 60px;
  opacity: 1;
  border-radius: 9px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 0.71) 0%,
    rgba(35, 133, 198, 0.71) 100%
  );
  box-shadow: 0px 10px 25px rgba(65, 167, 240, 0.4);
  color: #fff;
}

.login-button {
  width: 100px;
  height: 60px;
  opacity: 1;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  color: #000;
  margin-left: 10px;
}
</style>
