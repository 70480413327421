<template>
  <section class="faq-section">
    <h2 class="section-title">常见问题</h2>
    <div class="faq-content">
      <div class="faq-card" v-for="(faq, index) in faqs" :key="index">
        <h3 class="faq-category">{{ faq.category }}</h3>
        <div class="faq-divider"></div>
        <ul class="faq-list">
          <li
            v-for="(question, qIndex) in faq.questions"
            :key="qIndex"
            class="faq-question"
          >
            {{ question }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          category: "一种问题类型",
          questions: [
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
          ],
        },
        {
          category: "一种问题类型",
          questions: [
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
          ],
        },
        {
          category: "一种问题类型",
          questions: [
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
            "这是一个问题",
            "这是一个很长的问题这是一个很长的问题这是一个很长的问题",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.faq-section {
  background-color: #ffffff;
  text-align: center;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  vertical-align: top;
  margin-bottom: 40px;
}

.faq-content {
  width: 100%;
  margin: 0 auto;
  background: rgba(247, 249, 249, 1);
  border-radius: 16px;
  margin-bottom: 93px;

  display: flex;
  justify-content: space-around;
}

.faq-card {
  width: 32%;
  padding: 40px 55px 70px 55px;
  border-left: 2px solid rgba(238, 238, 238, 1);
  text-align: left;
}
.faq-card:first-child {
  border-left: none;
}

.faq-category {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(0, 0, 0, 1);
  margin: 0px;
  margin-bottom: 20px;
}

.faq-divider {
  border: 1px solid rgba(238, 238, 238, 1);
  width: 100%;
  margin: 20px 0;
}

.faq-list {
  list-style-type: disc;
  padding-left: 20px;
  padding-top: 10px;
}

.faq-question {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 35px;
}

.faq-col-divider {
  width: 2px;
  height: 715px;
  background: rgba(238, 238, 238, 1);
}
</style>
