<template>
  <el-main>
    <div class="product-intro">
      <FirstSection />
      <SecondSection />
      <RightImageIntro />
      <LeftImageIntro />
      <RightImageIntroV2 />
      <LeftImageIntroV2 />
      <!-- 其他部分可以继续添加 -->
    </div>
  </el-main>
</template>

<script>
import FirstSection from "./ProductIntroSection.vue";
import SecondSection from "./CenteredTextImageSection.vue";
import RightImageIntro from "./RightImageIntro.vue";
import LeftImageIntro from "./LeftImageIntro.vue";
import RightImageIntroV2 from "./RightImageIntroV2.vue";
import LeftImageIntroV2 from "./LeftImageIntroV2.vue";

export default {
  name: "ProductIntroContent",
  components: {
    FirstSection,
    SecondSection,
    RightImageIntro,
    LeftImageIntro,
    RightImageIntroV2,
    LeftImageIntroV2,
  },
};
</script>

<style scoped>
.el-main {
  padding: 0px;
  overflow: initial;
  width: 100vw;
  min-width: 1660px;
}
.product-intro {
  width: 100%;
  text-align: center;
  background: rgba(247, 249, 250, 1);
}
</style>
